import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { DefaultHttpHeadersService } from './default-request-options.service';
import { Login } from '../model/login';
import TokenSai from '../model/tokenSAI';
import { MenuStorageService } from './menu-storage.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly apiUrl = `${environment.apiUrl}/Autenticacao`;
  private readonly tokenKey = 'token-sai';

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private defaultHttpHeaders: DefaultHttpHeadersService,
    private menuStorageService: MenuStorageService
  ) { 
  }

  get token(): string|undefined {
    return this.localStorage.get(this.tokenKey);
  }

  set token(value: string|undefined) {
    value
      ? this.localStorage.set(this.tokenKey, value) 
      : this.localStorage.remove(this.tokenKey);
  }

  isLoggedIn(): boolean {
    return this.token != null;
  };

  isActive(): boolean {
    const token = this.token;
    return token
      ? new TokenSai(token).active
      : false;
  };

  login(instance: Login): Observable<any> {
    
    return this.http
      .post<any>(this.apiUrl, instance, this.defaultHttpHeaders.httpOptions())
      .pipe(
        tap((data: any) => {
          //this.check = true;
          this.token = data.access_token;
          this.menuStorageService.menus = data.menus;
          this.loopRefresh();
          //new ActiveService(this, this.jwtTokenSaiService).init();
        })
    );
  }

  loopRefresh() {    
    const tokenData = new TokenSai(this.token);
    setTimeout(() => {
      this.refreshToken().subscribe(
        (value: any): void => {
          //console.log(value);
        },
        (erro: any) => {
          //console.log(erro);
          this.logout();
        }
      );;
    }, tokenData.milliSeconds);
  }

  menu(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/MenuSAI`, this.defaultHttpHeaders.httpOptions())
      .pipe(
        tap((data: any) => {
          console.log(data);
        })
    );
  }

  refreshToken(): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/Refresh`, this.defaultHttpHeaders.httpOptions())
      .pipe(
        tap((data: any) => {
          this.token = data.access_token;
          this.loopRefresh();
        })
    );
  }

  clearSession(){
    window.localStorage.clear();
  }
  logout() {
    this.clearSession();
      // this.jwtToken.token = null;
      // this.jwtTokenSaiService.token = null;
      // new ActiveService(this, this.jwtTokenSaiService).finish();
    this.router.navigate(['login']);
      //this.check = false;
  }
}
