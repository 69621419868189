import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TreeNode, ITreeOptions } from '@circlon/angular-tree-component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as $ from 'jquery';

import { ToastService } from '../../../toast/toast.service';
import { Menu } from '../../../model/RH/menu';
import { Rota } from 'src/app/model/RH/rota';
import { SistemaService } from 'src/app/api/RH/sistema.service';
import { MenuService } from 'src/app/api/RH/menu.service';
import { IconeMenu } from 'src/app/model/RH/icone-menu';

@Component({
  selector: 'app-menu-lista',
  templateUrl: './menu-lista.component.html',
  styleUrls: ['./menu-lista.component.scss']
})
export class MenuListaComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    public toastService: ToastService,
    private sistemaService: SistemaService,
    private menuService: MenuService,
  ) { }

  ngOnInit() {
    this.getSistema();
  }
  nodes: Menu[] = [];
  novaRota: string = '';
  newNode: Menu = {
    Rotas: [],
    IconeMenu: {
    } as IconeMenu
  } as Menu;
  node: Menu = {} as Menu;
  @ViewChild('move', { static: true }) move: ElementRef;

  options: ITreeOptions = {
    idField: 'Cod',
    displayField: 'Titulo',
    childrenField: 'Filhos',
    allowDrag: true,
    nodeClass: (node: TreeNode) => {
      return node.data.Publico ? "public-class" : "";
    },
  };
  onEvent = ($event) => { }; //console.log($event);
  closeResult = '';
  selected: any = {};
  SistCod: number|null;
  optSistema: any = [];
  optTipoMenu: any = [];
  title: string = "Menu do Sistema";
  moved: any = {};

  onMoveNode($event) {
    this.moved.node = $event.node;

    console.log($event)
    this.moved.parent = ($event.to.parent.Cod == undefined) ? { Titulo: 'Raiz' } : $event.to.parent;

    this.modalService.open(this.move, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.menuService.drag(this.SistCod, this.moved.node.Cod, $event.to.index, this.moved.parent.Cod)
        .subscribe((res: any) => {
          this.getSystemId(this.SistCod);
        }, (err: any) => {
          this.getSystemId(this.SistCod);
          this.toastService.show('Registro não pode ser movido.', { classname: 'bg-danger text-light', delay: 10000 });
        });
    }, (reason) => {
      this.getSystemId(this.SistCod);
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addRoute(menu: Menu) {
    if(!this.novaRota || this.novaRota.trim().length == 0) {
      this.toastService.show('Rota não informada.', { classname: 'bg-warning text-light', delay: 3000 });
      return;
    }
    this.novaRota = this.novaRota.trim();
    if (!menu.Rotas) menu.Rotas = [];
    if(menu.Rotas.find(r => r.Caminho.toLowerCase() == this.novaRota.toLowerCase())){
      this.toastService.show('Rota já incluída.', { classname: 'bg-warning text-light', delay: 3000 });
      return;
    }

    const rou = {
      MenuSistCod: menu.SistCod,
      MenuCod: menu.Cod,
      Caminho: this.novaRota
    } as Rota;
    menu.Rotas.push(rou);
    this.novaRota = null;
  }

  removeRoute(menu: Menu, index: number) {
    if (menu.Rotas[index].Caminho == menu.RotaPrincipal)
       menu.RotaPrincipal = null;
    menu.Rotas.splice(index, 1);
  }

  addNode(content, treeModel: any) {
    console.log(treeModel.activeNodes[0])
    if (!this.SistCod) {
      this.toastService.show('sistema deve ser selecionado.', { classname: 'bg-danger text-light', delay: 10000 });
      return;
    }
    this.newNode = {
      //SistCod = this.systemId;
      // Cod = this.systemId;
      IconeMenu: {
        Binario: undefined
      } as IconeMenu
    } as Menu;
    if (treeModel.activeNodes.length == 0) {
      this.newNode.SistCod = this.SistCod;
      this.newNode.Cod = null;
    }
    else {
      this.newNode.SistCod = this.SistCod;
      this.selected = treeModel.activeNodes[0].data;
      this.newNode.MenuCod = this.selected.Cod;
    }
    this.newNode.Publico = false;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${treeModel.Id}`;
      this.menuService.create(this.newNode)
        .subscribe((res: Menu) => {
          this.getSystemId(this.SistCod);
          this.toastService.show('Registro incluído com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
        }, err => {
          this.toastService.show('Registro não pode ser incluído.', { classname: 'bg-danger text-light', delay: 10000 });
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  editNode(content, treeModel: any) {
    if (!this.SistCod) {
      this.toastService.show('Sistema deve ser selecionado.', { classname: 'bg-danger text-light', delay: 10000 });
      return;
    }
    if (treeModel.activeNodes.length == 0) {
      this.toastService.show('Menu deve ser selecionado.', { classname: 'bg-danger text-light', delay: 10000 });
      return;
    }

    let selected = treeModel.activeNodes[0].data;
    this.node.SistCod = selected.SistCod;
    this.node.Cod = selected.Cod;
    this.node.MenuCod = selected.MenuCod;
    this.node.RotaCod = selected.RotaCod;
    this.node.Titulo = selected.Titulo;
    this.node.Descricao = selected.Descricao;
    this.node.Publico = selected.Publico;
    this.node.Ordem = selected.Ordem;
    this.node.Rotas = selected.Rotas;
    this.node.RotaPrincipal = selected.RotaPrincipal;
    this.node.IconeMenu = {
      NomeIcone: selected?.IconeMenu?.NomeIcone,
      Binario: selected?.IconeMenu?.Binario,
      Tipo: selected?.IconeMenu?.Tipo,
    } as IconeMenu;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${selected.Id}`;
      this.menuService.update(this.node.SistCod, this.node.Cod, this.node)
        .subscribe((res: Menu) => {
          this.getSystemId(this.SistCod);
          this.toastService.show('Registro editado com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
        }, err => {
          this.toastService.show('Registro não pode ser editado.', { classname: 'bg-danger text-light', delay: 10000 });
          console.log(err);
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  removeNode(content, treeModel: any) {
    if (!this.SistCod) {
      this.toastService.show('Sistema deve ser selecionado.', { classname: 'bg-danger text-light', delay: 10000 });
      return;
    }
    if (treeModel.activeNodes.length == 0) {
      this.toastService.show('Menu deve ser selecionado.', { classname: 'bg-danger text-light', delay: 10000 });
      return;
    }

    this.selected = treeModel.activeNodes[0].data;

    if(!this.selected.IconeMenu ){
      this.selected.IconeMenu = {
        NomeIcone: undefined,
        Binario: undefined,
        Tipo: undefined,
      }
    }
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${this.selected.Id}`;
      this.menuService.delete(this.selected.SistCod, this.selected.Cod)
        .subscribe((res: Menu) => {
          this.getSystemId(this.SistCod);
          this.toastService.show('Registro excluido com sucesso.', { classname: 'bg-success text-light', delay: 10000 });
        }, err => {
          this.toastService.show('Registro não pode ser excluído.', { classname: 'bg-danger text-light', delay: 10000 });
          console.log(err);
        });
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getSystemId(sistCod: number | null): void {
    if (sistCod)
      this.menuService.getBySystemId(sistCod)
        .subscribe((res: Menu[]) => {
          this.nodes = res;
          console.log(res);
        }, (err: any) => {
          console.log(err);
        });
    else
      this.nodes = [];
  }

  getSistema(): void {
    this.sistemaService.getAll()
      .subscribe((res: any) => {
        this.optSistema = res;

      }, err => {
        console.log(err);
      });
  }

  onChangeSistema(event): void {
    this.getSystemId(this.SistCod);
  }

  onSubmit() {

  }

  toggleNavbar(): void {
    $("#wrapper").toggleClass("toggled");
  }

  public icon: any = "";
}
